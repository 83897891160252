@import '../../variables';

.main{
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
}

.myheading{
    padding-left: 30px;
    text-align: center;
}

.Btn {
    @include Button;
	@include PrimaryBtn;

    // background-color: #6648b1;
    margin: 5px;
}

.center {
    justify-items: center;
    text-align: center;
}

.signature_container {
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 50%;
}
    
.signature_canvas {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
}
  
.signature_label_container {
    position: absolute;
    bottom: 10px; 
    left: 60%;
    transform: translateX(-50%);
    width: 100%;
    font-size: small;
    color: grey;
}
  
.signature_clear {
    border-style: double;
}