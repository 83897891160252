@import '../../variables';

.main {
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
    padding: 10px;
}

.signature_container {
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 20px;
  // background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 50%;
}
  
.signature_canvas {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  // width: 100%;
}

.signature_label_container {
  position: absolute;
  bottom: 10px; 
  left: 60%;
  transform: translateX(-50%);
  width: 100%;
  font-size: small;
  color: grey;
}

.client_input {
  padding: 10px !important;
  margin: 5px !important;
  border: 1px solid black !important;
  border-radius: 5px !important;
  background-color: #f5f5f5 !important;
  font-size: 16px !important;
  text-align: left !important;
  width: 40% !important;
}

.signature_clear {
  border-style: double;
}

.btn_signature {
  width: 12%;
  height: 30px;
  display: block;
  color: #fff;
  background: #573b8a;
  font-size: 1em;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: .2s ease-in;
  cursor: pointer;

  .multiple_sign_jobs:hover{
    background: #6d44b8;
  }
}

.btn_container {
  display: flex;
  gap: 10px; 
}

/* Modal Container */
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal_content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.button_container {
  margin-top: 20px;
}

.modal_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal_cancel {
  margin: 0 10px;
  padding: 5px 20px;
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
}

.modal_continue {
  margin: 0 10px;
  padding: 5px 20px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
}

.jobslabel {
  padding-left: 10px;
  border: 1px solid black;
  width: 100%;
}

.jobsreadonlyedit {
  background-color:black !important;
  color: white !important;
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  margin: 0 !important;
}

.jobs_section {
  width: 100%;
  display: block;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: rgb(218, 210, 210) !important;
}

.jobs_check {
  color:white;
  border:2px solid black;
  background-color:DodgerBlue;
  width: 100%;
  padding:5px;
  font-size: 16px;
}

.jobs_check_value {
  border:2px solid black !important;
  width: 100% !important;
}

.jobs_notes {
  border:2px solid black;
  padding:5px;
  margin-bottom: 10px;
  width: 100%;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.jobshead {
  padding-top: 10px;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
  z-index: 9999; /* Ensure the loader is on top of other content */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Apply rotation animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-container span {
  margin-left: 10px; /* Add space between loader and text */
  font-size: 1.2em; /* Increase font size for better readability */
}

.warning {
  color:red;
}

.spacing {
  margin: 50px;
  text-align: left !important;
}

.my_edit{
  padding-left: 30px;
  text-align: left !important;
  border: 2px solid black !important;
  width: 99% !important;
  align-items: center !important;
}

.my_table {
  border-collapse: collapse;
  width: 95%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px !important;
  margin-left: 50px !important;
}


.my_table thead tr {
  background: blue;
  color: white;
  text-align: left;
  font-weight: bold;
}

.my_table th,
.my_table td {
  padding: 12px 15px;
}

.my_table tbody tr:hover {
  background-color: green;
  color: white;
  cursor: pointer;
}

.my_table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.my_table tbody tr:nth-of-type(even) tr:not(:last-child) {
  background-color: #f3f3f3;
}

.my_table tbody tr:nth-of-type(even):hover {
  background-color: green;
  color: white;
  cursor: pointer;
}

.my_table tbody tr:last-of-type {
  border-bottom: 2px solid blue;
}