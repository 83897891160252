@import '../../variables';

.main{
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
}

.myheading{
    padding-left: 30px;
    text-align: center;
}

label{
    padding-left: 30px;
}

.card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.syncBtn {
    padding: 1%;
    background-color: #56baed;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    transition: all 0.3s ease-in-out;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
    z-index: 9999; /* Ensure the loader is on top of other content */
}
  
.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Apply rotation animation */
}