/* Modal Container */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }
  
.modal.open {
    display: flex;
}

/* Modal Content */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
}

/* Close Button */
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

/* Buttons */
.button-container {
    margin-top: 20px;
}

.button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
}

.cancel {
    background-color: #d9534f;
    color: #fff;
}

.continue {
    background-color: #3498db;
    color: #fff;
}
  