@import '../../variables';

.FormWrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	max-width: 100%;
	margin: auto;
	padding: 5%;
	height: 100vh;
}

.LoginForm {
	width: 100%;
	max-width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
    'inputs inputs'
    'registerbtn loginbtn'
    'resetbtn resetbtn'
    'messages messages';
	justify-items: center;
	align-items: center;
	grid-gap: 24px;
}

.FormInputs {
	grid-area: inputs;
	display: grid;
	grid-template-columns: auto;
	align-items: center;
	grid-gap: 16px;
	width: 100%;

	label {
		justify-self: start;
	}
	input:focus {
		outline: unset;
	}
}

.RegisterBtn {
	grid-area: registerbtn;
	justify-content: center;
	justify-self: stretch;
	@include SecondaryBtn;
}

.LoginBtn {
	grid-area: loginbtn;
	justify-content: center;
	justify-self: stretch;
	@include Button;
	@include PrimaryBtn;
}

.ResetPassBtn {
	grid-area: resetbtn;
	justify-content: center;
	justify-self: stretch;
	@include Button;
	@include TertiaryBtn;
}

.Messages {
	grid-area: messages;
	justify-content: center;
	justify-self: stretch;

	font-size: 16px;
	text-align: center;
	line-height: 1.5;
	&:not(:last-child)  {
		margin-bottom: 1em;
	}
}

.EmailInput {
	grid-area: emailinput;
	display: grid;
	grid-template-columns: auto;
	align-items: center;
	width: 100%;
}

.BackLoginBtn {
	grid-area: backlogin;
	justify-content: center;
	justify-self: stretch;
	text-align: center;
	width: 100%;
	@include Button;
	@include TertiaryBtn;
}

.FinishRegisterBtn {
	@include PrimaryBtn;
	justify-content: center;
	justify-self: stretch;
	grid-area: finishreg;
}

.SuccessMsg {
	color: $success-green;
	.Icon, a {
		color: $success-green;
		font-weight: bold;
	}
}
.ErrorMsg {
	color: $error-red;
	.Icon, a {
		color: $error-red;
		font-weight: bold;
	}
}

.WarningMsg {
	justify-content: center;
	justify-self: stretch;
	text-align: center;
	color: $white;
	background-color: $warning-orange;
	border-radius: 5px;
	padding: 16px;
}


.Messages {
	grid-area: messages;
	justify-content: center;
	justify-self: stretch;

	font-size: 16px;
	text-align: center;
	line-height: 1.5;
	&:not(:last-child)  {
		margin-bottom: 1em;
	}
}

.Icon {
	grid-area: icon;
	color: $primary;
	text-align: center;
	i {
		font-size: 34px;
	}
}

.CenterText p {
	text-align: center;
}

.loader {
  border: 16px solid #f3f3f3;
	border-left: 16px solid #0080C5;
	border-right: 16px solid #0080C5;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.LoaderIcon i {
	animation: spin 1s linear infinite;
	color: $primary;
	font-size: 32px;
}

@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}