@import '../../variables';

.main{
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
}

.myheading{
    padding-left: 30px;
    text-align: center;
}

.my_text{
    padding-left: 30px;
    padding-bottom: 30px;
}

.my_label{
    padding-left: 30px;
    padding-bottom: 30px;
}

.my_edit{
    padding-left: 30px;
    text-align: left !important;
    border: 2px solid black !important;
    width: 99% !important;
    align-items: center !important;
}

.btnOk{
    align-items: center !important;
}

