@import '../../variables';

.main{
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
}

.msg_board_heading{
    padding-left: 30px;
    text-align: center;
}

.msg_board_text{
    padding-left: 30px;
    padding-bottom: 30px;
}

.home_logo{
    width: 100%;
    height: 100%;
}

.footer{
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: #3f3f3f;
    color: #d5d5d5;
    text-align: center;
}
