@import '../../variables';

.main{
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
}

.myheading{
    padding-left: 30px;
    text-align: center;
}

.my_text{
    padding-left: 30px;
    padding-bottom: 30px;
}

.my_label{
    padding-left: 30px;
    padding-bottom: 30px;
}

.my_edit{
    padding-left: 30px;
    text-align: left !important;
    border: 2px solid black !important;
    width: 99% !important;
    align-items: center !important;
}

.my_table {
    border-collapse: collapse;
    width: 95%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px !important;
    margin-left: 50px !important;
}

.my_table thead tr {
    background: blue;
    color: white;
    text-align: left;
    font-weight: bold;
}

.my_table th,
.my_table td {
    padding: 12px 15px;
}

.my_table tbody tr:hover {
    background-color: green;
    color: white;
    cursor: pointer;
}

.my_table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.my_table tbody tr:nth-of-type(even) tr:not(:last-child) {
    background-color: #f3f3f3;
}

.my_table tbody tr:nth-of-type(even):hover {
    background-color: green;
    color: white;
    cursor: pointer;
}

.my_table tbody tr:last-of-type {
    border-bottom: 2px solid blue;
}

.moola-last {
    background-color: black;
    color: white;
}