@import './variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap');

/* Custom CSS */
html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}



/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}



/* FORM TYPOGRAPHY*/

input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=email] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

input[type=password] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=text]:placeholder {
  color: #cccccc;
}

input[type=email]:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }

input[type=email]:placeholder {
    color: #cccccc;
  }

input[type=password]:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }

  input[type=password]:placeholder {
    color: #cccccc;
  }



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
}

#icon {
  width:20%;
}

/* End of Custom CSS */


.jobshead {
  padding-top: 10px;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin: auto; 
}

.jobsreadonlyedit {
  background-color:black;
  color: white;
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.jobslabel {
  padding-left: 10px;
  border: 1px solid black;
  width: 100%;
}

.jobs_section {
  width: 100%;
  display: block;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: rgb(218, 210, 210);
}

.jobs_text {
  padding-left: 10px;
  font-size: 1rem;
}

.signature-pad {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 10px;
  width: 100%;
  height: 300px;
  max-width: 700px;
  max-height: 460px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  padding: 16px;
}

.signature-pad::before,
.signature-pad::after {
  position: absolute;
  z-index: -1;
  content: "";
  width: 40%;
  height: 10px;
  bottom: 10px;
  background: transparent;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.signature-pad::before {
  left: 20px;
  -webkit-transform: skew(-3deg) rotate(-3deg);
  transform: skew(-3deg) rotate(-3deg);
}

.signature-pad::after {
  right: 20px;
  -webkit-transform: skew(3deg) rotate(3deg);
  transform: skew(3deg) rotate(3deg);
}

.signature-pad--body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #f4f4f4;
}

.signature-pad--body canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.signature-pad--footer {
  color: #C3C3C3;
  text-align: center;
  font-size: 1.2em;
  margin-top: 8px;
}

.signature-pad--actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
}

.jobs_check {
  color:white;
  border:2px solid black;
  background-color:DodgerBlue;
  width: 100%;
  padding:5px;
}

.jobs_notes {
  padding:5px;
  margin-bottom: 10px;
  width: 100%;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.container {
  display: flex;
}

.sidebar {
  align-items: center;
  width: 13%;
  padding: 20px;
  background-color: #333;
  display: flex;
  flex-direction: column;
  height: 100vh;
  top: 0;
  left: 0; 
  position: sticky;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
}

.carousel-inner img {
  width: 100%;
  height: 100%;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-caption h1 {
  font-size: 500%;
  text-transform: uppercase;
  text-shadow: 1px 1px 15px #000;
}

.carousel-caption h3 {
  font-size: 200%;
  font-weight: 500;
  text-shadow: 1px 1px 10px #000;
  padding-bottom: 1rem;
}

/*--- Bootstrap Padding Fix --*/

[class*="col-"] {
  padding: 1rem;
}

.wrapper {
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
width: 100%;
min-height: 100%;
padding: 20px;
}

#formContent {
-webkit-border-radius: 10px 10px 10px 10px;
border-radius: 10px 10px 10px 10px;
background: #fff;
padding: 30px;
width: 90%;
max-width: 450px;
position: relative;
padding: 0px;
-webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
text-align: center;
}

#formFooter {
background-color: #f6f6f6;
border-top: 1px solid #dce8f1;
padding: 25px;
text-align: center;
-webkit-border-radius: 0 0 10px 10px;
border-radius: 0 0 10px 10px;
}



/* TABS */

h2.inactive {
color: #cccccc;
}

h2.active {
color: #0d0d0d;
border-bottom: 2px solid #5fbae9;
}


/*---Firefox Bug Fix --*/

.carousel-item {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}


/*--- Fixed Background Image --*/

figure {
  position: relative;
  width: 100%;
  height: 60%;
  margin: 0!important;
}

.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#fixed {
  /* background-image: url('img/mac.png'); */
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}


/*---Media Queries --*/

@media (max-width: 992px) {
  .social a {
      font-size: 4em;
      padding: 2rem;
  }
}

@media (max-width: 768px) {
  .social a {
      font-size: 2.5em;
      padding: 1.2rem;
  }
  .carousel-caption {
      top: 45%;
  }
  .carousel-caption h1 {
      font-size: 350%;
  }
  .carousel-caption h3 {
      font-size: 140%;
      font-weight: 400;
      padding-bottom: .2rem;
  }
  .carousel-caption .btn {
      font-size: 95%;
      padding: 8px 14px;
  }
  .display-4 {
      font-size: 200%;
  }
}

@media (max-width: 576px) {
  .carousel-caption {
      top: 40%;
  }
  .carousel-caption h1 {
      font-size: 250%;
  }
  .carousel-caption h3 {
      font-size: 110%;
      font-weight: 400;
      padding-bottom: .2rem;
  }
  .carousel-caption .btn {
      font-size: 90%;
      padding: 4px 8px;
  }
  .carousel-indicators {
      display: none;
  }
  .display-4 {
      font-size: 160%;
  }
  .social a {
      font-size: 2em;
      padding: .7rem;
  }
}

.jumbotron {
  padding: 1rem;
  border-radius: 0;
}

.padding {
  padding-bottom: 2rem;
}

.welcome {
  width: 75%;
  margin: 0 auto;
  padding-top: 2rem;
}

.welcome hr {
  border-top: 2px solid #b4b4b4;
  width: 95%;
  margin-top: .3rem;
  margin-bottom: 1rem;
}

.social a {
  font-size: 4.5em;
  padding: 3rem;
}

.fa-facebook {
  color: #3b5998;
}

.fa-twitter {
  color: #00aced;
}

.fa-instagram {
  color: #517fa4;
}

.fa-youtube {
  color: #bb0000;
}

.fa-facebook:hover,
.fa-twitter:hover,
.fa-instagram:hover,
.fa-youtube:hover {
  color: #d5d5d5
}

footer {
  background-color: #3f3f3f;
  color: #d5d5d5;
  padding-top: 2rem;
}

hr.light {
  border-top: 1px solid #d5d5d5;
  width: 75%;
  margin-top: .8rem;
  margin-bottom: 1rem;
}

footer a {
  color: #d5d5d5;
  font-style: italic;
}

hr.light-100 {
  border-top: 1px solid #d5d5d5;
  width: 100%;
  margin-top: .8rem;
  margin-bottom: 1rem;
}

.moola-h1 {
  margin-left: 10px;
}

.moola-table {
  border-collapse: collapse;
  margin: 25px 25px;
  /* margin-left: 10px; */
  font-size: 0.9em;
  /* min-width: 400px; */
  width: 80%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.moola-table thead tr {
  background: blue;
  color: white;
  text-align: left;
  font-weight: bold;
}

.moola-table th,
.moola-table td {
  padding: 12px 15px;
}

.moola-table tbody tr:hover {
  background-color: green;
  color: white;
  cursor: pointer;
}

.moola-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.moola-table tbody tr:nth-of-type(even) tr:not(:last-child) {
  background-color: #f3f3f3;
}

.moola-table tbody tr:nth-of-type(even):hover {
  background-color: green;
  color: white;
  cursor: pointer;
}

.moola-table tbody tr:last-of-type {
  border-bottom: 2px solid blue;
}

.moola-last {
  background-color: black;
  color: white;
}

.databtns {
  padding: 2px 6px 2px 6px;
  background-color: black;
  color: white;
  border: 2px solid white;
  text-decoration: none;
}

.databtns:link {
  padding: 2px 6px 2px 6px;
  background-color: black;
  color: white;
  border: 2px solid white;
  text-decoration: none;
}

.databtns:visited {
  padding: 2px 6px 2px 6px;
  background-color: black;
  color: white;
  border: 2px solid white;
  text-decoration: none;
}

.databtns:hover {
  padding: 2px 6px 2px 6px;
  background-color: black;
  color: white;
  border: 2px solid white;
  text-decoration: none;
}

.databtns:active {
  padding: 2px 6px 2px 6px;
  background-color: black;
  color: white;
  border: 2px solid white;
  text-decoration: none;
}

.myblock {
  width: 80%;
}

.enter_data {
  width: 90%;
  padding-left: 5%;
}

.mybody {
  margin: 20px;
  width: 80%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Toastify Container */
.Toastify__toast--error {
  background: #dc3545 !important; 
  color: white !important;
  text-shadow: 0 0 white;
}

.Toastify__toast-body svg {
  fill: #fff !important;
}

.Toastify__toast--success {
  background-color: #52c41a !important;
  color: #fff !important; 
}

.Toastify__progress-bar {
  background-color: white !important;
}

.submitBtn {
  padding: 10px 30px !important; 
}

.removeElementsBtn {
  margin-bottom: 10px;
  background-color: #1976d2;
  border: none;
  color: white;
  padding: 5px 15px;
  margin: 5px 10px 20px 10px;
}

.templateHeader {
  color:white;
}

.templateList {
  font-size: medium;
  height: 30px;
}

.sidebarBtn {
  width: 100%;
  text-align: center;
}

.horizontal-line {
  margin: 10px 0;
  border: 1px solid white;
  width: 100%;
}

.templateInput {
  text-align-last: center;
  color:white;
}

.MuiDialogContent-root {
  overflow: hidden !important;
}

.InspectionCat {
  width: 100%;
  height: 40px;
  font-size: medium;
}

.formSelectOptions {
  width: 100%;
  margin-left: 10px;
}

.formSelect {
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.extraValueContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fieldType {
  display: table;
}

.textbox-selection-container {
  display: flex;
  align-items: center;
}