@import '../../variables';

.main{
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
}

.myheading{
    padding-left: 30px;
    text-align: center;
}

.my_text{
    padding-left: 30px;
    padding-bottom: 30px;
}

.my_label{
    padding-left: 30px;
    padding-bottom: 30px;
}

.my_edit{
    padding-left: 30px;
    text-align: left !important;
    border: 2px solid black !important;
    width: 99% !important;
    align-items: center !important;
}

.my_table {
    border-collapse: collapse;
    width: 95%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px !important;
    margin-left: 50px !important;
}

.my_table thead tr {
    background: blue;
    color: white;
    text-align: left;
    font-weight: bold;
}

.my_table th,
.my_table td {
    padding: 12px 15px;
}

.my_table tbody tr:hover {
    background-color: green;
    color: white;
    cursor: pointer;
}

.my_table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.my_table tbody tr:nth-of-type(even) tr:not(:last-child) {
    background-color: #f3f3f3;
}

.my_table tbody tr:nth-of-type(even):hover {
    background-color: green;
    color: white;
    cursor: pointer;
}

.my_table tbody tr:last-of-type {
    border-bottom: 2px solid blue;
}

.moola-last {
    background-color: black;
    color: white;
}

/* Modal Container */
.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}
  
/* Modal Content */
.modal_content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
}
  
.button_container {
    margin-top: 20px;
}
  
.modal_close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}
  
.modal_cancel {
    margin: 0 10px;
    padding: 5px 20px;
    cursor: pointer;
    background-color: #d9534f;
    color: #fff;
}
  
.modal_continue {
    margin: 0 10px;
    padding: 5px 20px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
}  

.button_finalize {
    background-color: #56baed;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    transition: all 0.3s ease-in-out;
}