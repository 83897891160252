@import '../../variables';

.main {
	margin: auto;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	align-items: center;
    background-color: white;
}

.formsBtn {
    @include Button;
	@include PrimaryBtn;

    background-color: #6648b1;
    margin: 5px;
}

.clientBtn {
    @include Button;
    @include SecondaryBtn;

    background-color: #6648b1;
}

.form {
    margin-left: 2%;
}

.clientHead {
    font-weight: bold;
    color: #505050;
}

.clientInput {
    background-color: lightgrey !important;
    text-align: left !important;
    width: 95% !important;
    padding: 15px 5px !important;
}

.categoryGrouping {
    display: grid;
    width: 30%;
}

.categoryHeader {
    color: black;
}